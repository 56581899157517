<template>
  <div class="postbackReport">
    <div class="title">
      <h2>Postback Report</h2>
    </div>
    <div class="bgf0">
      <el-row type="flex">
        <el-col :span="24" style="padding:16px;">
          <el-card shadow="hover" class="pad30">
            <el-form v-model="postbackReport" :inline="true" label-width="100px">
              <el-row>
                <el-col :lg="12" :md="20">
                  <label class="labelName">TranscationId(s)</label>
                  <el-form-item>
                    <el-input
                      v-model="postbackReport.transactionIds"
                      placeholder="transactionIds eg:123 or 123,456"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :lg="12" :md="20">
                  <label class="labelName">OfferId(s)</label>
                  <el-form-item>
                    <el-input
                      v-model="postbackReport.offerIds"
                      placeholder="offerIds: eg. 2206264 or 2206264,1567396"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :lg="12" :md="20">
                  <label class="labelName" style="margin-right:20px;">Affiliate(s)</label>
                  <el-form-item>
                    <group-select
                      v-model="postbackReport.affiliateIds"
                      :data-source="affiliatesGroupList"
                      multiple
                      reserve-keyword
                      :loading="affiliateLoading"
                      clearable
                      filterable
                      placeholder="请选择"
                      style="width:104%;"
                    />
                  </el-form-item>
                </el-col>
                <el-col :lg="12" :md="20">
                  <label class="labelName">Source(s)</label>
                  <el-form-item>
                    <group-select
                      v-model="postbackReport.sourceIds"
                      :data-source="sourcesList"
                      multiple
                      :loading="sourceLoading"
                      clearable
                      filterable
                      placeholder="请选择"
                      style="width:92%;"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <label class="labelName" style="margin-right:20px;">TimeZone</label>
                <el-form-item>
                  <el-select v-model="postbackReport.timezone" style="width:420px;">
                    <el-option
                      v-for="item in timezoneOption"
                      :key="item.value"
                      :value="item.number"
                      :label="item.label"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-row>
              <el-row>
                <label class="labelName" style="margin-right:60px;">Date</label>
                <el-form-item>
                  <el-date-picker
                    v-model="startToEndDate"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    type="daterange"
                    style="width:260px;"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions"
                  ></el-date-picker>
                </el-form-item>
              </el-row>
              <el-row>
                <el-col :lg="6" :md="20" :offset="1">
                  <el-form-item>
                    <el-button type="primary" @click="searchListClick(1)">In Browser</el-button>
                    <el-button type="primary" @click="exportExcelFileClick"
                      >Download Excel</el-button
                    >
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-card>
          <el-card shadow="hover">
            <el-table
              v-loading="listLoading"
              style="width:100%;"
              height="70vmin"
              stripe
              border
              :data="postbackReportList"
              highlight-current-row
            >
              <el-table-column
                label="Date"
                :fixed="fullWidth <= 768 ? false : 'left'"
                min-width="100"
                :resizable="false"
                align="center"
              >
                <template slot-scope="scope">
                  <span>{{ dateChange(scope.row.clickTime) }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="TransactionId"
                prop="transactionId"
                min-width="120"
                align="center"
                :fixed="fullWidth <= 768 ? false : 'left'"
                show-overflow-tooltip
                :resizable="false"
              ></el-table-column>
              <el-table-column
                label="Prod"
                prop="prod"
                align="center"
                :fixed="fullWidth <= 768 ? false : 'left'"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column label="Payout" prop="payoutValue"></el-table-column>
              <el-table-column
                label="Aff Payout"
                prop="affPayoutValue"
                min-width="100"
                align="center"
              ></el-table-column>
              <el-table-column
                label="TrackCountry"
                prop="trackCountry"
                min-width="120"
                align="center"
              ></el-table-column>
              <el-table-column label="Adjusted" min-width="100" align="center">
                <template slot-scope="scope">
                  <span v-if="scope.row.adjusted == 0">下发</span>
                  <span v-else-if="scope.row.adjusted == 1">扣除</span>
                  <span v-else></span>
                </template>
              </el-table-column>
              <el-table-column
                label="Source"
                prop="source"
                align="center"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                label="SourceId"
                prop="sourceId"
                min-width="100"
                align="center"
              ></el-table-column>
              <el-table-column
                label="Affiliate"
                prop="affiliate"
                min-width="120"
                align="center"
              ></el-table-column>
              <el-table-column
                label="AffiliateId"
                prop="affiliateId"
                min-width="100"
                align="center"
              ></el-table-column>
              <el-table-column
                label="AffiliateSub"
                prop="affiliateSub"
                min-width="100"
                align="center"
              ></el-table-column>
              <el-table-column
                label="OriginalUrl"
                prop="originalUrl"
                min-width="100"
                align="center"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                label="RedirectUrl"
                prop="redirectUrl"
                min-width="100"
                align="center"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                label="Create Time"
                prop="createTime"
                min-width="170"
                align="center"
                show-overflow-tooltip
              ></el-table-column>
            </el-table>
            <pagination
              class="block pagePanel"
              :pageTotal="total"
              @handleSizeChange="handleSizeChange"
              @handleCurrentChange="handleCurrentChange"
              :pageSize="pageSize"
              :currentPage="currentPage"
            ></pagination>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
  import { api, getPostbackReport } from 'api/report';
  import Pagination from '@/components/pagination';
  import GroupSelect from '@/components/GroupSelect';
  import qs from 'querystring';
  import dateUtils from '@/utils/dateutils.js';
  import { myMixin } from '@/mixins/mixins.js';
  import optionData from '@/assets/js/optionData';
  import { mapState, mapActions } from 'vuex';
  import { filterObject } from 'utils/utils';

  export default {
    mixins: [myMixin],
    components: {
      Pagination,
      GroupSelect,
    },
    data() {
      return {
        listLoading: false,
        pageSize: 20,
        currentPage: 1,
        fullWidth: document.documentElement.clientWidth,
        total: null,
        postbackReportList: [],
        startToEndDate: '',
        timezoneOption: optionData.timezoneOption,
        postbackReport: {
          sourceIds: null,
          affiliateIds: null,
          offerIds: null,
          transactionIds: null,
          fromDate: null,
          toDate: null,
          timezone: 0,
        },
        sourceIdArray: [],
      };
    },
    computed: {
      ...mapState('affiliate', {
        affiliatesGroupList: (state) => state.affiliatesGroupList,
        affiliateLoading: (state) => state.affiliateLoading,
      }),
      ...mapState('source', {
        sourcesList: (state) => state.sourcesList,
        sourceLoading: (state) => state.sourceLoading,
      }),
    },
    mounted() {
      //默认选择
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() + 3600 * 1000 * 24);
      this.startToEndDate = [];
      this.startToEndDate[0] = dateUtils.date2StrYMD(end);
      this.startToEndDate[1] = dateUtils.date2StrYMD(start);

      this.getAffiliateList();
      this.getSourceIdsList();
    },
    methods: {
      ...mapActions('affiliate', ['getAffiliateList']),
      ...mapActions('source', ['getSourceIdsList']),
      searchListClick(curPage) {
        if (curPage) {
          this.currentPage = curPage;
        }
        let param = {
          ...this.postbackReport,
          page: this.currentPage,
          pageSize: this.pageSize,
        };
        if (Array.isArray(param.affiliateIds)) {
          param.affiliateIds = param.affiliateIds.join(',');
        }
        if (Array.isArray(param.sourceIds)) {
          param.sourceIds = param.sourceIds.join(',');
        }
        if (Array.isArray(this.startToEndDate) && this.startToEndDate.length === 2) {
          param['fromDate'] = this.startToEndDate[0];
          param['toDate'] = this.startToEndDate[1];
        }
        param = filterObject(param);
        this.listLoading = true;

        getPostbackReport(param).then((response) => {
          this.listLoading = false;
          if (response.code === 200) {
            this.postbackReportList = response.result;
            this.total = response.total;
          } else {
            this.$message.error(response.message);
          }
        });
      },
      handleSizeChange(size) {
        this.pageSize = size;
        this.handleCurrentChange(1);
      },
      handleCurrentChange(currentPage) {
        this.currentPage = currentPage;
        this.searchListClick();
      },
      exportExcelFileClick() {
        let param = {
          ...this.postbackReport,
          page: this.currentPage,
          pageSize: this.pageSize,
        };
        if (Array.isArray(param.affiliateIds)) {
          param.affiliateIds = param.affiliateIds.join(',');
        }
        if (Array.isArray(param.sourceIds)) {
          param.sourceIds = param.sourceIds.join(',');
        }
        if (Array.isArray(this.startToEndDate) && this.startToEndDate.length === 2) {
          param['fromDate'] = this.startToEndDate[0];
          param['toDate'] = this.startToEndDate[1];
        }
        param = filterObject(param);
        const strParam = qs.stringify(param);
        window.open(`${api.DOWNLOAD_POSTBACK_REPORT}?${strParam}`, '_blank');
      },
      dateChange(time) {
        const date = new Date(time),
          Y = date.getFullYear() + '-',
          M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-',
          D = date.getDate() + ' ';
        return Y + M + D;
      },
    },
  };
</script>
<style lang="scss" scoped></style>
<style lang="scss"></style>
